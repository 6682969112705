import { useState } from 'react';
import { getUserExists, signIn, signUp } from 'apis/user-auth/queryFunction';
import { useRouter } from 'next/router';
import { useMountVideo } from 'helpers/hooks/useMountVideo';

import type {
  EmailModalValues,
  LoginModalValues,
  RegistrationModalValues
} from '../PianoModals';

import type { FlowVariant, Step } from './types';
import RegistrationStep from './RegistrationStep';
import EmailStep from './EmailStep';
import LoginStep from './LoginStep';

interface Props {
  onClose: VoidFunction;
  variant: FlowVariant;
}

const PianoRegistrationFlow = ({ onClose, variant }: Props) => {
  const router = useRouter();
  const { unmountVideo } = useMountVideo();
  const [step, setStep] = useState<Step>('email');
  const [email, setEmail] = useState('');

  const isDismissible = variant === 'free';

  switch (step) {
    case 'email':
      return (
        <EmailStep
          initialValues={{ email }}
          onBack={
            isDismissible
              ? undefined
              : () => {
                  unmountVideo();
                  router.push('/');
                }
          }
          onClose={isDismissible ? onClose : undefined}
          onSecondary={() => setStep('login')}
          onSubmit={async (values: EmailModalValues) => {
            setEmail(values.email);
            const userExists = await getUserExists(values.email);
            if (userExists) setStep('login');
            else setStep('registration');
          }}
          variant={variant}
        />
      );
    case 'registration':
      return (
        <RegistrationStep
          initialValues={{ email }}
          onBack={() => setStep('email')}
          onSubmit={async (credentials: RegistrationModalValues) => {
            await signUp(
              {
                email: credentials.email,
                newsletter_subscribed: credentials.agreements,
                password1: credentials.password,
                password2: credentials.password
              },
              router
            );
          }}
          variant={variant}
        />
      );
    case 'login':
      return (
        <LoginStep
          initialValues={{ email }}
          onBack={() => setStep('email')}
          onSubmit={(credentials: Required<LoginModalValues>) =>
            signIn(credentials, router)
          }
          variant={variant}
        />
      );
    default:
      return null;
  }
};

export default PianoRegistrationFlow;
