import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAnalyticsContext } from '@pocketoutdoormedia/analytics-provider';
import type {
  TrackingAdEvent,
  TrackingContentType,
  TrackingPlaybackEvent
} from '@pocketoutdoormedia/analytics-provider';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { getArrayOfTags } from 'helpers/utils/media/getArrayOfTags';
import { registerEvents } from 'helpers/utils/registerEvents';
import type { Events, PlaybackEvent } from 'helpers/utils/registerEvents';
import { useUserType } from 'helpers/hooks/useUserType';
import { noop } from 'helpers/utils/noop';

type AvailableEvents =
  | 'adClick'
  | 'adComplete'
  | 'adImpression'
  | 'beforeComplete'
  | 'beforePlay'
  | 'complete'
  | 'firstFrame'
  | 'mute'
  | 'pause'
  | 'play'
  | 'time';

type CustomEvents = Partial<
  Record<AvailableEvents, (event: PlaybackEvent) => void>
>;

type Input = CustomEvents & {
  contenttype: TrackingContentType;
  mediaItem: MediaItem;
  player: Nullable<JWPlayer>;
  playerId: string;
};

export const useRegisterPlayerEvents = ({
  contenttype,
  mediaItem,
  player,
  playerId,
  ...customEvents
}: Input) => {
  const { isLoggedIn } = useUserType();
  const hasBeenReached25Percent = useRef(false);
  const hasBeenReached50Percent = useRef(false);
  const hasBeenReached75Percent = useRef(false);

  const {
    category,
    duration,
    episodeMeta,
    mediaId,
    meterFlow,
    seriesPlaylistId,
    seriesSlug,
    tags,
    title
  } = mediaItem;

  const {
    videoAdClicked,
    videoAdCompleted,
    videoAdImpression,
    videoCompleted,
    videoMute,
    videoPaused,
    videoPlayed,
    videoQuartileFifty,
    videoQuartileSeventyFive,
    videoQuartileTwentyFive,
    videoStarted
  } = useAnalyticsContext();

  const handlePlayback = useCallback(
    (trackingEvent: TrackingPlaybackEvent) => (event) => {
      const position = player.getPosition();
      customEvents?.[event.type]?.(event);

      return trackingEvent({
        assetID: playerId,
        category,
        contenttype,
        duration,
        jwTags: getArrayOfTags(tags),
        mediaId,
        meterflow: meterFlow,
        mute: (event as jwplayer.MuteParam)?.mute ?? undefined,
        pauseReason:
          // there is no type in jwplayer with pauseReason prop
          (event as unknown as { pauseReason: string })?.pauseReason ??
          undefined,
        playReason:
          // there is no type in jwplayer with playReason prop
          (event as unknown as { playReason: string })?.playReason ?? undefined,
        playerId,
        playlistid: seriesPlaylistId,
        position,
        seriesslug: seriesSlug,
        signedIn: isLoggedIn,
        videoTitle: title,
        viewable: (event as jwplayer.TimeParam)?.viewable ?? undefined,
        ...(episodeMeta
          ? { episode: episodeMeta.episode, season: episodeMeta.season }
          : {})
      });
    },
    [
      category,
      contenttype,
      duration,
      episodeMeta,
      isLoggedIn,
      mediaId,
      meterFlow,
      customEvents,
      player,
      playerId,
      seriesPlaylistId,
      seriesSlug,
      tags,
      title
    ]
  );

  const handleOnTime = useCallback(
    (event: jwplayer.SeekParam) => {
      const { duration: seekDuration, position } = event;
      customEvents?.time?.(event);

      if (
        !hasBeenReached25Percent.current &&
        (position / seekDuration) * 100 > 25
      ) {
        handlePlayback(videoQuartileTwentyFive)(event);
        hasBeenReached25Percent.current = true;
      }

      if (
        !hasBeenReached50Percent.current &&
        (position / seekDuration) * 100 > 50
      ) {
        handlePlayback(videoQuartileFifty)(event);
        hasBeenReached50Percent.current = true;
      }

      if (
        !hasBeenReached75Percent.current &&
        (position / seekDuration) * 100 > 75
      ) {
        handlePlayback(videoQuartileSeventyFive)(event);
        hasBeenReached75Percent.current = true;
      }
    },
    [
      customEvents,
      handlePlayback,
      videoQuartileTwentyFive,
      videoQuartileFifty,
      videoQuartileSeventyFive
    ]
  );

  const handleAd = useCallback(
    (trackingEvent: TrackingAdEvent) => (event) => {
      const {
        adposition,
        adtitle,
        clickThroughUrl,
        duration: adDuration,
        viewable
      } = event;

      customEvents?.[event.type]?.(event);

      trackingEvent({
        adposition,
        adtitle,
        category,
        clickThroughUrl,
        duration: adDuration,
        jwTags: getArrayOfTags(tags),
        mediaId,
        meterflow: meterFlow,
        playerId,
        signedIn: isLoggedIn,
        videoTitle: title,
        viewable
      });
    },
    [
      category,
      customEvents,
      isLoggedIn,
      mediaId,
      meterFlow,
      playerId,
      tags,
      title
    ]
  );

  const handleAdComplete = handleAd(videoAdCompleted);
  const handleAdImpression = handleAd(videoAdImpression);
  const handleAdClick = handleAd(videoAdClicked);
  const handlePause = handlePlayback(videoPaused);
  const handlePlay = useCallback(
    (event: jwplayer.PlayParam) => {
      const position = player.getPosition();

      /** Some of the videos start from 0.1 as an initial position - we need to make a 0.1 offset to consider the video as a newly played  */
      if (event.oldstate === 'buffering' && position <= 0.1) {
        return handlePlayback(videoStarted)(event);
      }

      return handlePlayback(videoPlayed)(event);
    },
    [handlePlayback, player, videoPlayed, videoStarted]
  );
  const handleMute = handlePlayback(videoMute);
  const handleCompleted = handlePlayback(videoCompleted);
  const handleBeforeComplete = useCallback(
    (event) => {
      customEvents?.beforeComplete?.(event);
    },
    [customEvents]
  );
  const handleBeforePlay = useCallback(
    (event) => {
      customEvents?.beforePlay?.(event);
    },
    [customEvents]
  );

  const events = useMemo(
    (): Partial<Events> => ({
      adClick: handleAdClick,
      adComplete: handleAdComplete,
      adImpression: handleAdImpression,
      beforeComplete: handleBeforeComplete,
      beforePlay: handleBeforePlay,
      complete: handleCompleted,
      mute: handleMute,
      pause: handlePause,
      play: handlePlay,
      time: handleOnTime
    }),
    [
      handleAdClick,
      handleAdComplete,
      handleAdImpression,
      handleBeforeComplete,
      handleBeforePlay,
      handleCompleted,
      handleMute,
      handlePause,
      handlePlay,
      handleOnTime
    ]
  );

  useEffect(() => {
    if (!player) return noop;

    registerEvents('on', player, events);

    return () => {
      registerEvents('off', player, events);
    };
  }, [events, player]);
};
